<script lang="ts" setup>
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

const props = withDefaults(
	defineProps<{
		type: "link" | "button";
		title?: string;
		link: string;
		iconHtml?: string;
		iconLink?: string;
		active?: boolean;
		dataTid?: string;
	}>(),
	{ type: "link" }
);

const { isExpanded, handleClickExpand } = useGameSearch();

const emit = defineEmits<{ (event: "clickTab", link: string): void }>();

const isSvgCode = computed(() => props.iconHtml?.trim()?.startsWith("<svg"));
const isSvgPath = computed(() => props.iconHtml?.trim()?.endsWith(".svg"));
const handleClick = () => {
	emit("clickTab", props.link);
	handleClickExpand(false);
};
</script>

<template>
	<div
		v-if="type === 'button'"
		:class="['tab', { 'tab-active': active && !isExpanded }]"
		:data-tid="dataTid"
		@click="handleClick"
	>
		<ASvg v-if="iconLink" :name="iconLink" class="icon" />
		<i v-else-if="iconHtml && isSvgCode" class="icon" v-html="iconHtml" />
		<NuxtImg
			v-else-if="iconHtml && isSvgPath"
			:src="`${baseImageUrl}${iconHtml}`"
			width="24"
			height="24"
			loading="lazy"
			alt="category"
			:class="['icon icon-img', { svg: isSvgPath }]"
		/>
		<AText :size="{ full: 12, md: 10 }" class="title text-neutral-100" :modifiers="['center']">{{ title }}</AText>
	</div>
	<NuxtLink
		v-else
		:to="link"
		:class="['tab', { 'tab-active': active && !isExpanded }]"
		:data-tid="dataTid"
		@click="handleClickExpand(false)"
	>
		<ASvg v-if="iconLink" :name="iconLink" class="icon" />
		<i v-else-if="iconHtml && isSvgCode" class="icon" v-html="iconHtml" />
		<NuxtImg
			v-else-if="iconHtml && isSvgPath"
			:src="`${baseImageUrl}${iconHtml}`"
			width="24"
			height="24"
			loading="lazy"
			alt="category"
			:class="['icon icon-img', { svg: isSvgPath }]"
		/>
		<AText :size="{ full: 12, md: 10 }" class="title text-neutral-100" :modifiers="['center']">{{ title }}</AText>
	</NuxtLink>
</template>

<style lang="scss" scoped>
.tab {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	gap: 4px;
	height: 56px;
	min-width: 72px;
	padding: 8px 2px;
	border-radius: 8px;
	background: var(--gray-900);
	flex-shrink: 0;
	cursor: pointer;

	@include media-breakpoint-up(md) {
		min-width: 104px;
	}

	.svg {
		filter: invert(66%) sepia(12%) saturate(333%) hue-rotate(90deg) brightness(98%) contrast(91%);
	}

	&.tab-active {
		background: var(--gray-800);
		pointer-events: none;

		.title {
			color: var(--neutral-50);
		}

		.icon {
			color: var(--primary-400);

			&:deep(svg path) {
				fill: var(--primary-400);
			}
		}

		.svg {
			filter: invert(76%) sepia(88%) saturate(517%) hue-rotate(350deg) brightness(90%) contrast(95%);
		}
	}

	&:hover {
		.title {
			color: var(--neutral-50);
		}
	}

	.icon {
		display: inline-flex;
		width: 24px;
		height: 24px;
		font-size: 24px;
		color: var(--neutral-300);

		&:deep(svg) {
			max-width: 24px;
			max-height: 24px;
		}

		&:deep(path) {
			stroke: transparent;
			fill: var(--neutral-300);
		}
	}
}
</style>
